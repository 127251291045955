import React, { useContext } from "react";
import "./static/Resume.css";
import "./static/Overview.css";
import { DarkModeContext } from "../context/DarkModeContext";

const Resume = () => {
    const { darkMode } = useContext(DarkModeContext);

    return (
        <div className="resume__container">
            <object
                data="/Resume_Chanon Pakeenuya.pdf"
                type="application/pdf"
                width="100%"
                height="100%"
                className="pdf"
            >
                <p className={darkMode ? "dark__text" : "light__text"}>
                    Cannot display on this device <br />
                    <a
                        href="https://files.txrxx.dev/document/Resume_ChanonPakeenuya.pdf"
                        className={darkMode ? "dark__text" : "light__text"}
                    >
                        Download the PDF!
                    </a>
                </p>
            </object>
        </div>
    );
};

export default Resume;
