import React from "react";
import "./Home.css";
import { HomeComp } from "../../components";
import { DarkModeProvider } from "../../context/DarkModeContext";

function Home() {
    return (
            <DarkModeProvider>
                <HomeComp/>
            </DarkModeProvider>
    );
}

export default Home;
