import React, { useContext, useState } from "react";
import { DarkModeContext } from "../context/DarkModeContext";
import { TypeAnimation } from "react-type-animation";
import Overview from "./Overview";
import Experience from "./Experience";
import Resume from "./Resume";

const HomeComp = () => {
    const handleClickScroll = () => {
        const element = document.getElementById("section__bottom");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    const { darkMode, toggleDarkMode } = useContext(DarkModeContext);
    const handleClick = () => {
        toggleDarkMode();
    };

    const [isOverviewActive, setOverviewActive] = useState(true);
    const [isExperienceActive, setExperienceActive] = useState(false);
    const [isResumeActive, setResumeActive] = useState(false);

    const toggleOverview = () => {
        setOverviewActive(true);
        setExperienceActive(false);
        setResumeActive(false);
    };

    const toggleExperience = () => {
        setOverviewActive(false);
        setExperienceActive(true);
        setResumeActive(false);
    };

    const toggleResume = () => {
        setOverviewActive(false);
        setExperienceActive(false);
        setResumeActive(true);
    };

    // console.log(isOverviewActive, isExperienceActive, isResumeActive);

    return (
        <div className={darkMode ? `wrapper wrapper-dark` : `wrapper`}>
            <div
                className={
                    darkMode ? `mode__btn dark-btn` : `mode__btn light-btn`
                }
                onClick={handleClick}
            >
                <img src={darkMode ? `/w-moon.png` : `w-sun.png`} alt="" />
            </div>
            <div
                className={
                    darkMode
                        ? `sectione__top sectione__top-dark`
                        : `sectione__top sectione__top-light`
                }
            >
                <div className="wrap__header">
                    {/* <h1>Hello, Stranger</h1> */}
                    <TypeAnimation
                        sequence={[
                            "Hello, Stranger",
                            3000,
                            "How are you ?",
                            5000,
                        ]}
                        wrapper="h1"
                        cursor={true}
                        repeat={Infinity}
                        className="type"
                        speed={30}
                    />
                    <h2>TXRXX's SPACE.</h2>
                </div>
                <button
                    className={
                        darkMode
                            ? `scroll__container scroll__container-dark`
                            : `scroll__container`
                    }
                    onClick={handleClickScroll}
                >
                    <span>SWIPE DOWN</span>
                    <img
                        src={darkMode ? `/w-chevron.png` : `/b-chevron.png`}
                        alt="arrow-icon"
                        className="chevron"
                    />
                </button>
            </div>
            <div className="outter__bottom">
            <div className="section__bottom" id="section__bottom">
                <div className={darkMode ? `menus menus-dark` : `menus`}>
                    <div
                        className={
                            isOverviewActive
                                ? darkMode
                                    ? `menu-dark menu-active-dark`
                                    : `menu-light menu-active`
                                : darkMode
                                ? `menu-dark`
                                : `menu-light`
                        }
                        onClick={toggleOverview}
                    >
                        OVERVIEW
                    </div>
                    <div
                        className={
                            isExperienceActive
                                ? darkMode
                                    ? `menu-dark menu-active-dark`
                                    : `menu-light menu-active`
                                : darkMode
                                ? `menu-dark`
                                : `menu-light`
                        }
                        onClick={toggleExperience}
                    >
                        EXPERIENCE
                    </div>
                    <div
                        className={
                            isResumeActive
                                ? darkMode
                                    ? `menu-dark menu-active-dark`
                                    : `menu-light menu-active`
                                : darkMode
                                ? `menu-dark`
                                : `menu-light`
                        }
                        onClick={toggleResume}
                    >
                        RESUME
                    </div>
                </div>
                <div
                    className={
                        darkMode ? `container container-dark` : `container`
                    }
                >
                    {isOverviewActive ? <Overview/> : null}
                    {isExperienceActive ? <Experience/> : null}
                    {isResumeActive ? <Resume/> : null}
                </div>
            </div>
            </div>
        </div>
    );
};

export default HomeComp;
