import React, { useContext } from "react";
import "./static/Overview.css";
import { DarkModeContext } from "../context/DarkModeContext";

const Overview = () => {
    const { darkMode } = useContext(DarkModeContext);

    return (
        <div
            className={darkMode ? "ov__container dark__text" : "ov__container"}
        >
            <div className="ov__top">
                <div className="ov__img__container"></div>
                <div className="ov__line"></div>
                <div className="ov__wrap__p">
                    <span className="ov__name">Chanon Pakeenuya</span>
                    <p>
                        Now, I'm a student at KhonKaen University, College of
                        Computing, B.A. Computer Science. I'm currently learning
                        web-application and site realiability engineering.
                    </p>
                    <div className="ov__wrap__contact">
                        <div className="contact__row">

                        
                            <a className={darkMode ? "ov__contact dark__contact" : "ov__contact"} href="mailto:p.chanon@kkumail.com">
                                <img src={darkMode ? "/email_w.png" : "/email_b.png"} alt="" />
                                <span>p.chanon@kkumail.com</span>
                            </a>
                                <a className={darkMode ? "ov__contact dark__contact" : "ov__contact"} href="https://www.linkedin.com/in/chanon-pakeenuya" target="_blank">
                                    <img src={darkMode ? "/linkedin_w.png" : "/linkedin_b.png"} alt="" />
                                    <span>Chanon Pakeenuya</span>
                                </a>
                                </div>
                                <a className={darkMode ? "ov__contact dark__contact" : "ov__contact"} href="https://github.com/TXRXX" target="_blank">
                                    <img src={darkMode ? "/github_w.png" : "/github_b.png"} alt="" />
                                    <span>TXRXX</span>
                                </a>

                    </div>
                </div>
            </div>
            <div className={darkMode ? "ov__body dark__text" : "ov__body"}>
                <span className="ov__body__position">
                    FRONTEND DEVELOPER & SITE RELIABILITY ENGINEER
                </span>
                <div className="ov__wrap__skill">
                    {/* <span className="ov__skill__title">Skills</span> */}
                    <div className="ov__skill__content">
                        <ul
                            className={
                                darkMode ? "darkmode__ul" : "lightmode__ul"
                            }
                        >
                            <span>Frontend Developer</span>
                            <li>- React JS</li>
                            <li>- React Native</li>
                            <li>- JQuery</li>
                            <li>- HTML</li>
                            <li>- CSS</li>
                            <li>- Figma</li>
                        </ul>
                        <div className="ov__skill__wrap__sre">
                            <ul
                                className={
                                    darkMode ? "darkmode__ul" : "lightmode__ul"
                                }
                            >
                                <span>Site Reliablity Engineer</span>
                                <li>- Linux</li>
                                <li>- Nginx</li>
                                <li>- Cloudflare</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Overview;
