import React, { useContext } from "react";
import "./static/Experience.css";
import { DarkModeContext } from "../context/DarkModeContext";

const Experience = () => {
    const { darkMode } = useContext(DarkModeContext);

    return (
        <div className="exp__container">
            <div className="exp__wrap__item">
                {/* AFU Website */}
                <div
                    className={darkMode ? "exp__item item__dark" : "exp__item"}
                >
                    <div className="exp__item__top">
                        <div
                            className={
                                darkMode
                                    ? "exp__item__name item__name__dark"
                                    : "exp__item__name"
                            }
                        >
                            AWAY FROM US Website
                        </div>
                        <div className="exp__item__menu">
                            <a
                                className={
                                    darkMode
                                        ? "item__link link__dark"
                                        : "item__link"
                                }
                                href="https://awayfromus.dev"
                                target="_blank"
                            >
                                <img
                                    src={
                                        darkMode
                                            ? "/global_w.png"
                                            : "/global_b.png"
                                    }
                                    alt=""
                                />
                            </a>
                        </div>
                    </div>
                    <div className="exp__item__bottom">
                        <div
                            className={
                                darkMode
                                    ? "exp__item__desc item__desc__dark"
                                    : "exp__item__desc"
                            }
                        >
                            UX/UI Design and Site Reliability Enginner
                            <br />
                            <p
                                className={
                                    darkMode ? "item__info__dark" : "item__info"
                                }
                            >
                                Contributors of FiveM resources using ReactJs
                                and TypeScript for frontend development and
                                using NodeJS for backend development. Use
                                virtual private server and Nginx for hosting
                                website and cloudflare for DNS management.
                            </p>
                        </div>
                    </div>
                </div>
                {/* AFU Resources */}
                <div
                    className={darkMode ? "exp__item item__dark" : "exp__item"}
                >
                    <div className="exp__item__top">
                        <div
                            className={
                                darkMode
                                    ? "exp__item__name item__name__dark"
                                    : "exp__item__name"
                            }
                        >
                            AWAY FROM US FiveM Resources
                        </div>
                        <div className="exp__item__menu">
                            <a
                                className={
                                    darkMode
                                        ? "item__link link__dark"
                                        : "item__link"
                                }
                                href="https://awayfromus.dev/store"
                                target="_blank"
                            >
                                <img
                                    src={
                                        darkMode
                                            ? "/global_w.png"
                                            : "/global_b.png"
                                    }
                                    alt=""
                                />
                            </a>
                        </div>
                    </div>
                    <div className="exp__item__bottom">
                        <div
                            className={
                                darkMode
                                    ? "exp__item__desc item__desc__dark"
                                    : "exp__item__desc"
                            }
                        >
                            UX/UI Design and Frontend developer
                            <br />
                            <p
                                className={
                                    darkMode ? "item__info__dark" : "item__info"
                                }
                            >
                                Developed FiveM resources by using VueJS,
                                Javascript, HTML and CSS for frontend and using
                                LUA for backend.
                            </p>
                        </div>
                    </div>
                </div>
                {/* Weather Forecast */}
                <div
                    className={darkMode ? "exp__item item__dark" : "exp__item"}
                >
                    <div className="exp__item__top">
                        <div
                            className={
                                darkMode
                                    ? "exp__item__name item__name__dark"
                                    : "exp__item__name"
                            }
                        >
                            Weather Forecast
                        </div>
                        <div className="exp__item__menu">
                            <a
                                className={
                                    darkMode
                                        ? "item__link link__dark"
                                        : "item__link"
                                }
                                href="https://weather.txrxx.dev/"
                                target="_blank"
                            >
                                <img
                                    src={
                                        darkMode
                                            ? "/global_w.png"
                                            : "/global_b.png"
                                    }
                                    alt=""
                                />
                            </a>
                            <a
                                className={
                                    darkMode
                                        ? "item__link link__dark"
                                        : "item__link"
                                }
                                href="https://github.com/TXRXX/weather-forecast-react"
                                target="_blank"
                            >
                                <img
                                    src={
                                        darkMode
                                            ? "/github_w.png"
                                            : "/github_b.png"
                                    }
                                    alt=""
                                />
                            </a>
                        </div>
                    </div>
                    <div className="exp__item__bottom">
                        <div
                            className={
                                darkMode
                                    ? "exp__item__desc item__desc__dark"
                                    : "exp__item__desc"
                            }
                        >
                            {/* UX/UI Design and SRE */}
                            {/* <br /> */}
                            <p
                                className={
                                    darkMode ? "item__info__dark" : "item__info"
                                }
                            >
                                Fetched GeoDB Cities API for get country
                                latitude and longitude Then Fetched OpenWeather
                                API for get weather data.
                            </p>
                        </div>
                    </div>
                </div>
                {/* Password Generator */}
                <div
                    className={darkMode ? "exp__item item__dark" : "exp__item"}
                >
                    <div className="exp__item__top">
                        <div
                            className={
                                darkMode
                                    ? "exp__item__name item__name__dark"
                                    : "exp__item__name"
                            }
                        >
                            Password Generator
                        </div>
                        <div className="exp__item__menu">
                            <a
                                className={
                                    darkMode
                                        ? "item__link link__dark"
                                        : "item__link"
                                }
                                href="https://password.txrxx.dev/"
                                target="_blank"
                            >
                                <img
                                    src={
                                        darkMode
                                            ? "/global_w.png"
                                            : "/global_b.png"
                                    }
                                    alt=""
                                />
                            </a>
                            <a
                                className={
                                    darkMode
                                        ? "item__link link__dark"
                                        : "item__link"
                                }
                                href="https://github.com/TXRXX/password-generator-react"
                                target="_blank"
                            >
                                <img
                                    src={
                                        darkMode
                                            ? "/github_w.png"
                                            : "/github_b.png"
                                    }
                                    alt=""
                                />
                            </a>
                        </div>
                    </div>
                    <div className="exp__item__bottom">
                        <div
                            className={
                                darkMode
                                    ? "exp__item__desc item__desc__dark"
                                    : "exp__item__desc"
                            }
                        >
                            <p
                                className={
                                    darkMode ? "item__info__dark" : "item__info"
                                }
                            >
                                The Website that can select option such as
                                Uppercase letter, Lowercase letter, Numbers and
                                Symbols for generate password. Developed by
                                using ReactJS
                            </p>
                        </div>
                    </div>
                </div>
                {/* Quick Math */}
                <div
                    className={darkMode ? "exp__item item__dark" : "exp__item"}
                >
                    <div className="exp__item__top">
                        <div
                            className={
                                darkMode
                                    ? "exp__item__name item__name__dark"
                                    : "exp__item__name"
                            }
                        >
                            Quick Math
                        </div>
                        <div className="exp__item__menu">
                            <a
                                className={
                                    darkMode
                                        ? "item__link link__dark"
                                        : "item__link"
                                }
                                href="https://github.com/TXRXX/QuickMath-JQuery"
                                target="_blank"
                            >
                                <img
                                    src={
                                        darkMode
                                            ? "/github_w.png"
                                            : "/github_b.png"
                                    }
                                    alt=""
                                />
                            </a>
                        </div>
                    </div>
                    <div className="exp__item__bottom">
                        <div
                            className={
                                darkMode
                                    ? "exp__item__desc item__desc__dark"
                                    : "exp__item__desc"
                            }
                        >
                            <p
                                className={
                                    darkMode ? "item__info__dark" : "item__info"
                                }
                            >
                                Quick Math game that can play stage to stage and
                                has player score developed by using JQuery.
                            </p>
                        </div>
                    </div>
                </div>

                {/* URL Shortener */}
                <div
                    className={darkMode ? "exp__item item__dark" : "exp__item"}
                >
                    <div className="exp__item__top">
                        <div
                            className={
                                darkMode
                                    ? "exp__item__name item__name__dark"
                                    : "exp__item__name"
                            }
                        >
                            URL Shortener
                        </div>
                        <div className="exp__item__menu">
                            <a
                                className={
                                    darkMode
                                        ? "item__link link__dark"
                                        : "item__link"
                                }
                                href="https://url.txrxx.dev/"
                                target="_blank"
                            >
                                <img
                                    src={
                                        darkMode
                                            ? "/global_w.png"
                                            : "/global_b.png"
                                    }
                                    alt=""
                                />
                            </a>
                            <a
                                className={
                                    darkMode
                                        ? "item__link link__dark"
                                        : "item__link"
                                }
                                href="https://github.com/TXRXX/url-shortener"
                                target="_blank"
                            >
                                <img
                                    src={
                                        darkMode
                                            ? "/github_w.png"
                                            : "/github_b.png"
                                    }
                                    alt=""
                                />
                            </a>
                        </div>
                    </div>
                    <div className="exp__item__bottom">
                        <div
                            className={
                                darkMode
                                    ? "exp__item__desc item__desc__dark"
                                    : "exp__item__desc"
                            }
                        >
                            <p
                                className={
                                    darkMode ? "item__info__dark" : "item__info"
                                }
                            >
                                URL Shortener using EJS for frontend. And using
                                MongoDB for collect FullUrl and ShortUrl If user
                                input exist FullUrl then user will get Exist
                                ShortUrl.
                            </p>
                        </div>
                    </div>
                </div>
                {/* Marget */}
                <div
                    className={darkMode ? "exp__item item__dark" : "exp__item"}
                >
                    <div className="exp__item__top">
                        <div
                            className={
                                darkMode
                                    ? "exp__item__name item__name__dark"
                                    : "exp__item__name"
                            }
                        >
                            Marget Web Application
                        </div>
                        <div className="exp__item__menu">
                            <a
                                className={
                                    darkMode
                                        ? "item__link link__dark"
                                        : "item__link"
                                }
                                target="_blank"
                                href="https://github.com/TXRXX/marget-project"
                            >
                                <img
                                    src={
                                        darkMode
                                            ? "/github_w.png"
                                            : "/github_b.png"
                                    }
                                    alt=""
                                />
                            </a>
                        </div>
                    </div>
                    <div className="exp__item__bottom">
                        <div
                            className={
                                darkMode
                                    ? "exp__item__desc item__desc__dark"
                                    : "exp__item__desc"
                            }
                        >
                            UX/UI Design and Frontend Developer
                            <br />
                            <p
                                className={
                                    darkMode ? "item__info__dark" : "item__info"
                                }
                            >
                                This is a project of Database Management System
                                subject. The website that user can promote thier
                                own shop. Frontend developed by ReactJS using
                                Cloudinary for collect images and Backend
                                Developed by NodeJS
                            </p>
                        </div>
                    </div>
                </div>
                {/* CS-KKU */}
                <div
                    className={darkMode ? "exp__item item__dark" : "exp__item"}
                >
                    <div className="exp__item__top">
                        <div
                            className={
                                darkMode
                                    ? "exp__item__name item__name__dark"
                                    : "exp__item__name"
                            }
                        >
                            CS-KKU Web Design
                        </div>
                        <div className="exp__item__menu">
                            <a
                                className={
                                    darkMode
                                        ? "item__link link__dark"
                                        : "item__link"
                                }
                                target="_blank"
                                href="https://github.com/TXRXX/WebDesign-Project"
                            >
                                <img
                                    src={
                                        darkMode
                                            ? "/github_w.png"
                                            : "/github_b.png"
                                    }
                                    alt=""
                                />
                            </a>
                        </div>
                    </div>
                    <div className="exp__item__bottom">
                        <div
                            className={
                                darkMode
                                    ? "exp__item__desc item__desc__dark"
                                    : "exp__item__desc"
                            }
                        >
                            <p
                                className={
                                    darkMode ? "item__info__dark" : "item__info"
                                }
                            >
                                This is project of Web Design subject. The
                                requirement is design website for College of
                                computing.
                            </p>
                        </div>
                    </div>
                </div>
                {/* FiveM */}
                <div
                    className={darkMode ? "exp__item item__dark" : "exp__item"}
                >
                    <div className="exp__item__top">
                        <div
                            className={
                                darkMode
                                    ? "exp__item__name item__name__dark"
                                    : "exp__item__name"
                            }
                        >
                            FiveM Resources UX/UI Design
                        </div>
                        <div className="exp__item__menu">
                            <a
                                className={
                                    darkMode
                                        ? "item__link link__dark"
                                        : "item__link"
                                }
                                href="https://awayfromus.dev"
                                target="_blank"
                            >
                                <img
                                    src={
                                        darkMode
                                            ? "/global_w.png"
                                            : "/global_b.png"
                                    }
                                    alt=""
                                />
                            </a>
                        </div>
                    </div>
                    <div className="exp__item__bottom">
                        <div
                            className={
                                darkMode
                                    ? "exp__item__desc item__desc__dark"
                                    : "exp__item__desc"
                            }
                        >
                            UX/UI Design and Frontend Developer
                            <br />
                            <p
                                className={
                                    darkMode ? "item__info__dark" : "item__info"
                                }
                            >
                                Redesign and Custom User Interface for FiveM
                                resources. And using JQuery to render data.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrap__blob">
                <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fill="rgba(255, 133, 173, 0.4)"
                        d="M37.2,-61C45.4,-59.8,47.1,-44,49.9,-31.4C52.8,-18.8,56.7,-9.4,58.5,1C60.2,11.4,59.9,22.9,53.1,28.7C46.3,34.5,33.2,34.6,23.3,38.4C13.3,42.2,6.7,49.8,1.1,47.9C-4.6,46.1,-9.1,34.9,-17.1,29.9C-25,25,-36.3,26.2,-47.8,22.3C-59.2,18.3,-70.8,9.2,-68.9,1.1C-67.1,-7,-51.8,-14.1,-46,-27.8C-40.3,-41.6,-44,-62.1,-37.9,-64.5C-31.8,-66.9,-15.9,-51.1,-0.7,-49.9C14.6,-48.7,29.1,-62.2,37.2,-61Z"
                        transform="translate(200 50)"
                    />
                    <path
                        fill="rgba(255, 184, 122, 0.4)"
                        d="M34,-39.7C42.1,-25.8,45.5,-12.9,50.3,4.8C55.1,22.5,61.3,45,53.1,52.1C45,59.2,22.5,51,5.1,45.9C-12.4,40.9,-24.7,39,-34.5,31.9C-44.3,24.7,-51.4,12.4,-53.4,-1.9C-55.3,-16.2,-52,-32.5,-42.3,-46.4C-32.5,-60.3,-16.2,-71.9,-1.7,-70.2C12.9,-68.5,25.8,-53.6,34,-39.7Z"
                        transform="translate(100 100)"
                    />
                    <path
                        fill="rgba(31, 221, 255, 0.4)"
                        d="M45,-45.8C45.3,-35.5,23.6,-17.6,17.7,2.5C11.9,22.6,22.1,44.7,20.7,46.9C19.3,49.1,6.4,31.4,-7,22.1C-20.4,12.7,-34.2,11.8,-45.7,-0.5C-57.3,-12.9,-66.7,-36.8,-58.7,-48.8C-50.7,-60.9,-25.3,-61.1,-1.5,-59.9C22.4,-58.7,44.7,-56.1,45,-45.8Z"
                        transform="translate(90 0)"
                    />
                    <path
                        fill="rgba(255, 219, 222, 0.4)"
                        d="M37.9,-54.6C52.5,-49.7,70,-44.8,75.6,-34.1C81.2,-23.4,74.8,-6.8,72.8,10.8C70.8,28.4,73.2,46.9,63.7,51.7C54.1,56.5,32.7,47.5,17.9,44.1C3.1,40.7,-5,42.8,-11.9,40.7C-18.8,38.6,-24.3,32.2,-35.8,26.7C-47.2,21.2,-64.6,16.5,-74.2,5.5C-83.8,-5.4,-85.5,-22.7,-78.4,-34.7C-71.2,-46.8,-55.1,-53.8,-40.6,-58.8C-26,-63.8,-13,-66.8,-0.7,-65.8C11.7,-64.7,23.3,-59.6,37.9,-54.6Z"
                        transform="translate(200 100)"
                    />
                    <path
                        fill="rgba(255, 133, 173, 0.4)"
                        d="M37.2,-61C45.4,-59.8,47.1,-44,49.9,-31.4C52.8,-18.8,56.7,-9.4,58.5,1C60.2,11.4,59.9,22.9,53.1,28.7C46.3,34.5,33.2,34.6,23.3,38.4C13.3,42.2,6.7,49.8,1.1,47.9C-4.6,46.1,-9.1,34.9,-17.1,29.9C-25,25,-36.3,26.2,-47.8,22.3C-59.2,18.3,-70.8,9.2,-68.9,1.1C-67.1,-7,-51.8,-14.1,-46,-27.8C-40.3,-41.6,-44,-62.1,-37.9,-64.5C-31.8,-66.9,-15.9,-51.1,-0.7,-49.9C14.6,-48.7,29.1,-62.2,37.2,-61Z"
                        transform="translate(30 160)"
                    />
                </svg>
            </div>
        </div>
    );
};

export default Experience;
